<template>
  <div class="login">
    <a-form :form="form">
      <div class="login__header__switching__buttons__container">
        <a-button
          style="border-radius: 0px"
          type="link"
          class="login__header__switching__button__active"
        >
          Вход
        </a-button>
        <router-link to="/registration_customer">
          <a-button type="link" class="login__header__switching__button">
            Регистрация
          </a-button>
        </router-link>
      </div>

      <a-form-item :validateStatus="statusLogin" :help="helpLogin">
        <a-input
          class="a-form-item-input"
          placeholder="Логин:"
          :value="form.email"
          v-on:input="updateFormLogin($event)"
          size="large"
        >
          <template v-slot:prefix>
            <img src="./svg/person-red.svg" />
          </template>
        </a-input>
      </a-form-item>

      <a-form-item :validateStatus="statusPassword" :help="helpPassword">
        <a-input-password
          class="login__a-form-item-input"
          placeholder="Пароль:"
          :value="form.password"
          v-on:input="updateFormPassword($event)"
          size="large"
        >
          <template v-slot:prefix>
            <img src="./svg/lock.svg" />
          </template>
        </a-input-password>
      </a-form-item>

      <a-form-item>
        <div class="login__asign__user__agree__container">
          <div class="login__asign-user-agree">
            <a-checkbox :indeterminate="rememberCheckbox" @change="onChange" class="checkbox-style">
              Запомнить меня
            </a-checkbox>
          </div>

          <router-link to="/reset_password">
            <div class="login__button__entry__account">
              <div>Забыли пароль?</div>
            </div>
          </router-link>
        </div>
      </a-form-item>
      <a-form-item>
        <a-button class="login__button__registration" @click="submitForm()">
          Войти
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { login } from '@/helpers/authHelper'
import { validateEmail } from '@/helpers/validation'
// import { isLogged, isMaster, isCustomer } from "@/helpers/authHelper";
import axios from 'axios'

export default {
  components: {},
  data () {
    return {
      rememberCheckbox: true,
      statusLogin: 'success',
      helpLogin: '',
      statusPassword: 'success',
      helpPassword: '',
      form: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    onChange (e) {
      this.rememberCheckbox = e.target.checked
    },
    submitForm () {
      if (this.form.email === '') {
        this.statusLogin = 'error'
        this.helpLogin = 'Пустое поле'
      }
      if (!validateEmail(this.form.email)) {
        this.statusEmail = 'error'
        this.helpEmail = 'Некорректная почта'
      }
      if (this.form.password === '') {
        this.statusPassword = 'error'
        this.helpPassword = 'Пустое поле'
      }
      if (this.form.password.length < 5) {
        this.statusPassword = 'error'
        this.helpPassword = 'Пароль не может быть меньше 5 символов'
      }

      if (this.statusLogin === 'success' && this.statusPassword === 'success') {
        this.sendForm()
      }
    },

    updateFormLogin (e) {
      this.form.email = e.target.value
      this.statusLogin = 'success'
      this.helpLogin = ''
    },
    updateFormPassword (e) {
      this.form.password = e.target.value
      this.statusPassword = 'success'
      this.helpPassword = ''
    },
    sendForm () {
      axios
        .post(process.env.VUE_APP_SERVER_URL + '/login', {
          email: this.form.email,
          password: this.form.password
        })
        .then((response) => {
          if (response.data.data.token) {
            login(
              {
                image: response.data.data.image,
                name: response.data.data.name,
                token: response.data.data.token,
                userType: response.data.data.roles.name,
                uuid: response.data.data.uuid,
                isRemember: this.rememberCheckbox
              },
              this.rememberCheckbox
            )
            this.$router.push('/main')
          } else {
            this.$notification.open({
              message: 'Ошибка входа в систему',
              description: response.data.message
            })
          }
        })
        .catch((error) => {
          let description = error.response.data.message
          if (error.response.data.data && error.response.data.data.email) {
            description += error.response.data.errors.email[0]
          }
          if (error.response.data.data && error.response.data.data.password) {
            description += error.response.data.errors.password[0]
          }
          this.$notification.open({
            message: 'Сетевая ошибка входа в систему',
            description
          })
        })
    }
  }
}
</script>

<style lang="scss">
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 40px;
}
.login {
  padding: 110px 102px;
  background-color: #f6f6f6;
  width: 588px;
  &__header__switching {
    &__buttons__container {
      width: 100%;
      border-bottom: 1px solid #f0f0f0;
      margin-bottom: 29px;
    }
    &__button {
      color: black;
      &__active {
        border-bottom: 2px solid #b12021;
        color: #b12021;
      }
    }
  }
  &__button {
    &__registration {
      color: white;
      height: 40px;
      background-color: #b12021;
      width: 100%;
    }
    &__entry__account {
      color: #b12021;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
  &__a-form-item-input {
    height: 40px;
  }

  &__asign__user__agree__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

@media(max-width: 992px) {
  .login {
    padding: 30px 40px;
  }
}

@media(max-width: 768px) {
  .login {
    padding: 20px;
  }
}

@media(max-width: 576px) {
  .login {
    width: 100%;
  }
}

</style>
